import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password", "toggle"]

  connect() {
    console.log('password show controller connected');
  }

  toggle() {
    const type = this.passwordTarget.getAttribute('type');
    if (type === 'password') {
      this.passwordTarget.setAttribute('type', 'text');
      this.toggleTarget.innerHTML = '<i class="fe fe-eye-off"></i>';
    } else {
      this.passwordTarget.setAttribute('type', 'password');
      this.toggleTarget.innerHTML = '<i class="fe fe-eye"></i>';
    }
  }
}
